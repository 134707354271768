@import "./globles";

.instructionBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto auto auto;
    height: 80%;
    max-height: 46rem;
    max-width: 38rem;
    width: 80%;
    min-width: 30rem;
    border-radius: 10px;
    background-color: white;
    z-index: 6;
    overflow: hidden;

    header {
        position: relative;
        font-size: 2rem;
        padding: 0.5rem 0 0.5rem 0;
        text-align: center;
        background-color: $baseColour;
        font-weight: bold;
    }

    body {
        padding: 1rem;
        font-size: 1.2rem;
        overflow: auto;
        height: 80%;
        background-color: white;

        span {
            color: red;
        }

        h3 {
            text-align: center;
            margin: 0;

            span {
                color: red;
            }
        }

        h4 {
            margin: 0;
            font-size: 1.4rem;
        }
    }
}