body {
  margin: 0;
  font-family: "Oxygen";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:-webkit-any-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

a:-webkit-any-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  margin-block: 1em;
}

::-webkit-scrollbar-thumb {
  border: .13em solid white;
  border-radius: 25px;
  background: rgb(211, 231, 245);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(211, 231, 245);
}