.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: opacity(0.6);
    background-color: rgba(148, 175, 185, 0.7);
}
