$baseColourL1: rgb(231, 241, 248);
$baseColour: rgb(211, 231, 245);
$baseColourD1: rgb(192, 218, 236);
$baseColourD3: rgb(28, 91, 137);
$baseColourD5: rgb(16, 51, 76);
$buttonColour: rgb(129, 159, 181);
$buttonColourD1: rgb(99, 135, 160);
$buttonColourD2: rgb(73, 108, 132);
$instructionColour: rgb(181, 129, 136);
$instructionColourD1: rgb(146, 103, 109);
$instructionColourD2: rgb(104, 74, 78);
$greyL2: rgb(224, 224, 224);
$greyL1: rgb(190, 190, 190);
$grey: rgb(160, 160, 160);
$greyD1: rgb(130, 130, 130);
$greyD2: rgb(100, 100, 100);

$trueColour: rgb(107, 199, 150);
$falseColour: rgb(199, 107, 107);

$backgroundColour: rgb(225, 232, 236);
$containerShadow1: rgba(50, 50, 93, 0.25) 0px 1px 1px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
