.notFound {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Oxygen";
}

.notFound img {
    width: 25%;
    min-width: 10rem;
    max-width: 15rem;
}

.notFound h1 {
    margin: 0;
    font-size: 6rem;
}

.notFound h2 {
    margin-top: 0;
    font-size: 1.5rem;
}

.notFound button {
    width: 10rem;
    height: 3rem;
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: black;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;}}