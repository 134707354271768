@import "./globles";

.pageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto auto 2rem auto;
    width: 80%;
    max-width: 100rem;
    height: 90%;
    background-color:white;
    border-radius: 10px;
    box-shadow: $containerShadow1;
    overflow: hidden;

    p {
        margin: 0;;
    }

    header {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        background-color: $baseColourD1;

        .infoButtonContainer {
            .registerButton {
                top: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
                left: 28rem;
                min-width: 8rem;
            }
    
            .rankingButton {
                top: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
                left: 38rem;
                min-width: 8rem;
            }
        }

        select {
            width: 15rem;
            margin-left: 5rem;
            background-color: transparent;
            font-family: 'Dancing Script', cursive;
            font-size: 2.5rem;
            font-weight: bold;
            text-align: center;
            color: white;
            border: none;
        }

        .timeButtonContainer {
            display: flex;
        }

        .leftTriangle {
            cursor: pointer;
            width: 0; 
            height: 0; 
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 16px solid $buttonColour;
            margin-right: 0.25rem;
            margin-left: 0.25rem;

            &:hover {
                border-right: 16px solid $buttonColourD1;
            }
        }

        .rightTriangle {
            cursor: pointer;
            width: 0; 
            height: 0; 
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 16px solid $buttonColour;
            margin-right: 0.25rem;
            margin-left: 0.25rem;

            &:hover {
                border-left: 16px solid $buttonColourD1;
            }
        }

        option {
            font-family: 'Oxygen', sans-serif;
            font-size: 1.2rem;
            color: black;
        }

        h2 {
            width: 100%;
            text-align: right;
            margin-right: 5%;
            color: white;
        }
    }

    .calendarContainer {
        position: absolute;
        display: grid;
        grid-template-columns: 20% auto;
        height: 90%;
        width: 100%;
        overflow: auto;
    }

    .rightCalendar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 2rem auto auto auto auto auto auto 5rem;

        .dateText {
            font-weight: bold;
        }

        .sunday {
            color: rgb(231, 80, 80);
        }
    }

    .leftCalendar {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 2.6rem auto auto auto auto auto auto 5rem;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .dateDisable {
        color: $greyL2;
    }

    .weekend h4 {
        color: rgb(231, 80, 80);
    }

    .dayContainerSelected {
        background-color: $baseColourL1;
    }

    .dayboxContainer {
        margin: 0 0.25rem 0 0;
        padding: 0.5rem 0.5rem 0.5rem 0;
        border-radius: 5px;

        h4 {
            margin: 0;

            .canRegister {
                height: 0.75rem;
                width: 0.75rem;
                background-color: $trueColour;
                border-radius: 50%;
                display: inline-block;
            }

            .cannotRegister {
                height: 0.75rem;
                width: 0.75rem;
                background-color: $falseColour;
                border-radius: 50%;
                display: inline-block;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            position: relative;
        }

        ul li {
            display: block;
        }

        .arrow_box { 
            background: white; 
            border-radius: 10px;
            position: absolute;
            top: 0rem;
            left: 30px;
            display: block;
            width: 12rem;
            z-index: 1;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            overflow: auto;
            color: black;

            h3 {
                margin: 0;
                padding: 0.5rem;
                background-color: $baseColour;
            }

            p {
                margin: 0.5rem;
            }
        }

        .arrow_box_left { 
            background: white; 
            border-radius: 10px;
            position: absolute;
            top: 0rem;
            right: 100px;
            display: block;
            width: 12rem;
            z-index: 1;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            overflow: auto;
            color: black;

            h3 {
                margin: 0;
                padding: 0.5rem;
                background-color: $baseColour;
            }

            p {
                margin: 0.5rem;
            }
        }

        .empty {
            color: $greyL1;
        }
    }
}

@media only screen and (max-width: 1320px) {
    .pageContainer {
        width: 97.5%;

        header {
            display: grid;
            grid-template-columns: repeat(3, 1fr) 2fr;
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;

            h2 {
                text-align: center;
            }
            

            select {
                width: 10rem;
                margin-left: 5%;
                background-color: transparent;
                font-family: 'Dancing Script', cursive;
                font-size: 2rem;
                font-weight: bold;
                text-align: center;
                color: white;
                border: none;
            }

            .infoButtonContainer {
                grid-area: 2 / 1 / 3 / 5;
                display: flex;

                .registerButton {
                    top: 2.5rem;
                    bottom: 0;
                    margin: auto;
                    left: 1rem;
                }
    
                .rankingButton {
                    top: 2.5rem;
                    left: 10rem;
                }
            }
    
            option {
                font-family: 'Oxygen', sans-serif;
                font-size: 1rem;
                color: black;
            }

            h2 {
                font-size: 1.25rem;
            }

            input {
                font-size: 1.5rem;
                width: 5rem;
            }
        }

        .calendarContainer {
            grid-template-columns: 100%;
            justify-items: center;
        }

        .leftCalendar {
            display: none;
        }

        .rightCalendar {
            width: 100%;
        }

        .dayboxContainer {
            margin: 0;
            padding-right: 0;

            .arrow_box { 
                left: 26px;
            }

            .arrow_box_left {
                right: 50px;
            }

            .showFull {
                display: none;
            }

            h4 {
                background-color: $baseColourL1;
                width: 100%;

                .canRegister {
                    height: 0.75rem;
                    width: 0.75rem;
                    background-color: $trueColour;
                    border-radius: 50%;
                    display: inline-block;
                }
    
                .cannotRegister {
                    height: 0.75rem;
                    width: 0.75rem;
                    background-color: $falseColour;
                    border-radius: 50%;
                    display: inline-block;
                }
            }
        }
    }
}