@import "./globles";

.main {
    display: flex;
    flex-direction: column;
    background-color: $backgroundColour;
    height: 100vh;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10rem;
    margin: 0 5% 0 5%;

    button {
        margin: 0 1rem 0 1rem;
    }

    .websiteName {
        display: flex;
        align-items: center;

        h1 {
            font-size: 2rem;
            color: $baseColourD5;
        }

        img {
            margin-top: 0.4rem;
            width: 4.5rem;
            height: 5rem;
        }
    }
}

@media only screen and (max-width: 2200px) {
    .header {
        flex-direction: column;
        height: 12rem;
        margin: 1rem 0 0 0;

        button {
            margin: 0.5rem 0.25rem 1rem 0.25rem;
            height: 2rem;
            font-size: 1rem;
        }

        .websiteName {
            h1 {
                font-size: 1.5rem;
            }

            img {
                width: 3.5rem;
                height: 4rem;
            }
        }
    }
}