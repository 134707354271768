@import "./globles";

.rankingBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto auto auto;
    height: 80%;
    max-height: 34rem;
    max-width: 78rem;
    width: 80%;
    min-width: 30rem;
    border-radius: 10px;
    background-color: white;
    z-index: 6;
    overflow: auto;

    header {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        font-size: 2rem;
        padding: 0.5rem 5rem 0.5rem 5rem;
        text-align: center;
        background-color: $baseColour;
        font-weight: bold;

        p {
            grid-area: 1 / 1 / 2 / 5; 
            margin: 0.5rem 0 0.5rem 0;
            font-size: 1.5rem;
        }

        .closeButton {
            position: absolute;
            right: 1rem;
            top: -2.5rem;
        }

        .leftTriangle {
            cursor: pointer;
            width: 0; 
            height: 0; 
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 16px solid $buttonColour;
            margin-right: 0.25rem;
            margin-left: 0.25rem;
            align-self: center;
            justify-self: end;

            &:hover {
                border-right: 16px solid $buttonColourD1;
            }
        }

        .rightTriangle {
            cursor: pointer;
            width: 0; 
            height: 0; 
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 16px solid $buttonColour;
            margin-right: 0.25rem;
            margin-left: 0.25rem;
            align-self: center;

            &:hover {
                border-left: 16px solid $buttonColourD1;
            }
        }

        select {
            width: 100%;
            justify-self: center;
            background-color: transparent;
            font-family: 'Dancing Script', cursive;
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            color: black;
            border: none;
        }

        input {
            border: none;
            background-color: transparent;
            font-family: 'Dancing Script', cursive;
            font-size: 1.5rem;
            width: 5rem;
            text-align: center;
        }
    }

    .statContainer {
        flex-direction: column;
        max-width: 78rem;
        background-color: white;
        border-radius: 10px;
        width: 100%;
        overflow: auto;

        p {
            margin: 0;
            padding: 0.5rem;
        }

        .rightline {
            border-right: 1px solid lightgrey;
            height: 100%;
        }

        .subHeader {
            border-bottom: solid lightgrey 1px;
        }

        .subHeader, .rankingContainer {
            display: grid;
            grid-template-columns: 5rem 10rem 6rem 6rem 6rem 6rem 6rem 6rem 6rem 6rem 6rem 8rem;
            text-align: center;
            width: 100%;
        }
    }
}