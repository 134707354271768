@import "./globles";

.defaultButtonL {
    width: 10vw;
    min-width: 7rem;
    max-width: 8rem;
    height: 3rem;
    color: white;
    background-color: $buttonColour;
    border: none {
        radius: 30px;
    }
    font: {
        family: "oxygen";
        size: 1.4rem;
    }

    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: $buttonColourD1;
    }

    &:active {
        background-color: $buttonColourD2;
    }
}

.defaultButtonM {
    width: 8rem;
    height: 2.5rem;
    color: white;
    background-color: $grey;
    border: none {
        radius: 30px;
    }
    font: {
        family: "oxygen";
        size: 1.1rem;
    }
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: $greyD1;
    }

    &:active {
        background-color: $greyD2;
    }
}

.instructionButtonL {
    width: 8vw;
    min-width: 7rem;
    max-width: 9rem;
    height: 2rem;
    color: white;
    background-color: $instructionColour;
    border: none {
        radius: 30px;
    }
    font: {
        family: "oxygen";
        size: 1rem;
    }

    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: $instructionColourD1;
    }

    &:active {
        background-color: $instructionColourD2;
    }
}

.closeButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;  
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 20px;
    border: 2px white solid;
    font-weight: bold;
    color: white;
    background-color: $baseColour;

    &:hover {
        background-color: $baseColourD1;
    }

    &:active {
        color: grey;
        border: 2px grey solid;
    }
}